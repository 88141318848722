import {
  addDays,
  addWeeks,
  firstOfYear,
  getMonday,
  weeksBetween,
} from "./date-math";
import { Positioners } from "./grids-types";
import {
  AppCalculatedArrangement,
  BoxCol,
  BoxRow,
  CoordX,
  CoordY,
  makeBoxCol,
  makeBoxRow,
  makeCoordX,
  makeCoordY,
  Week,
  WeekArrangement,
  WeekIndex,
} from "./types";
import { DateRange } from "../common/types";

export interface Sizes {
  readonly marginLeft: number;
  readonly marginTop: number;
  readonly boxWidth: number;
  readonly boxHeight: number;
  readonly boxStrokeWidth: number;
  readonly boxHorizontalSpacing: number;
  readonly boxVerticalSpacing: number;
  readonly boxFullWidth: number;
  readonly boxFullHeight: number;
}

const boxWidth = 10;
const boxHeight = 7;
const boxStrokeWidth = 1;

export const sizes: Sizes = {
  marginLeft: 30,
  marginTop: 13,

  boxWidth: boxWidth,
  boxHeight: boxHeight,
  boxStrokeWidth: boxStrokeWidth,
  boxHorizontalSpacing: 1,
  boxVerticalSpacing: 1,
  boxFullWidth: boxWidth + 2 * boxStrokeWidth,
  boxFullHeight: boxHeight + 2 * boxStrokeWidth,
};

export function buildArrangement(
  dateOfBirth: Date,
  weekArrangement: WeekArrangement
): AppCalculatedArrangement {
  const weeks: Week[] = getWeeks(dateOfBirth);
  const [col, row] = gridArrangers(weekArrangement, weeks[0]);
  const [x, y, midX, midY] = coordArrangers(col, row);
  return {
    weeks,
    col,
    row,
    x,
    y,
    midX,
    midY,
    xLabels: getXLabels(weekArrangement, weeks),
    yLabels: getYLabels(weekArrangement, weeks),
    weekIndexer: (date: Date): WeekIndex =>
      weekIndexFromDateAndStartDate(date, weeks[0].start),
    gridWidth: d3.max(
      d3.range(weeks.length).map((wi) => (col(wi as WeekIndex) + 1) as BoxCol)
    )!,
    gridHeight: (row((weeks.length - 1) as WeekIndex) + 1) as BoxRow,
  };
}

export function forEachWeekIndex<T>(
  ranges: DateRange[],
  weekIndexer: (date: Date) => WeekIndex,
  lastWeekIndex: WeekIndex,
  f: (wi: WeekIndex) => T
): T[] {
  const output: T[] = [];
  for (const range of ranges) {
    const start: WeekIndex = weekIndexer(range.from);
    const end: WeekIndex =
      range.to === undefined ? lastWeekIndex : weekIndexer(range.to);
    for (let i: WeekIndex = start; i <= end; i++) {
      output.push(f(i));
    }
  }

  return output;
}

function getWeeks(dateOfBirth: Date): Week[] {
  const start: Date = getMonday(dateOfBirth);
  // ~90 years worth of weeks
  const weeks: Week[] = [];
  const weekCount = 91 * 52; // =  4732
  for (let i: number = 0; i < weekCount; i++) {
    weeks.push(makeWeek(addDays(start, i * 7)));
  }

  return weeks;
}

function makeWeek(monday: Date): Week {
  return {
    start: monday,
    end: addDays(monday, 6),
    entries: [],
    events: [],
  };
}

/*
 * Gets the coordinates of the actual boxes, used for positioning them
 */
export const boxCoords: Positioners = {
  top: (row: number) => yFromBoxRow(row as BoxRow),
  bottom: (row: number) =>
    (yFromBoxRow(row as BoxRow) + sizes.boxFullWidth) as CoordY,
  left: (col: number) => xFromBoxCol(col as BoxCol),
  right: (col: number) =>
    (xFromBoxCol(col as BoxCol) + sizes.boxFullWidth) as CoordX,
};

/*
 * Gets the coordinates in the middle between boxes, used for entry paths
 */
export const midCoords: Positioners = {
  top: (row: number) => midYFromBoxRow(row as BoxRow),
  bottom: (row: number) => midYFromBoxRow((row + 1) as BoxRow),
  left: (col: number) => midXFromBoxCol(col as BoxCol),
  right: (col: number) => midXFromBoxCol((col + 1) as BoxCol),
};

export function weekIndexFromDateAndStartDate(
  date: Date,
  startDate: Date
): WeekIndex {
  return weeksBetween(startDate, date) as WeekIndex;
}

function xFromBoxCol(boxCol: BoxCol): CoordX {
  return makeCoordX(
    sizes.marginLeft +
      boxCol * (sizes.boxFullWidth + sizes.boxHorizontalSpacing)
  );
}

function yFromBoxRow(boxRow: BoxRow): CoordY {
  return makeCoordY(
    sizes.marginTop + boxRow * (sizes.boxFullHeight + sizes.boxVerticalSpacing)
  );
}

function midXFromBoxCol(boxCol: BoxCol): CoordX {
  return makeCoordX(
    xFromBoxCol(boxCol) - sizes.boxHorizontalSpacing / 2 - boxStrokeWidth
  );
}

function midYFromBoxRow(boxRow: BoxRow): CoordY {
  return makeCoordY(
    yFromBoxRow(boxRow) - sizes.boxVerticalSpacing / 2 - boxStrokeWidth
  );
}

export function boxColFromX(x: CoordX): BoxCol {
  return makeBoxCol(
    Math.floor(
      (x - sizes.marginLeft - sizes.boxHorizontalSpacing / 2) / //sizes.boxFullWidth / 2) /
        (sizes.boxFullWidth + sizes.boxHorizontalSpacing)
    )
  );
}

export function boxRowFromY(y: CoordY): BoxRow {
  return makeBoxRow(
    Math.floor(
      (y - sizes.marginTop - sizes.boxVerticalSpacing / 2) / //sizes.boxFullHeight / 2) /
        (sizes.boxFullHeight + sizes.boxVerticalSpacing)
    )
  );
}

function colByEvenGrid(weekIndex: WeekIndex): BoxCol {
  return makeBoxCol(weekIndex % 52);
}

function rowByEvenGrid(weekIndex: WeekIndex): BoxRow {
  return makeBoxRow(Math.floor(weekIndex / 52));
}

function colByCalendar(weekIndex: WeekIndex, firstWeek: Week): BoxCol {
  const thisWeekStart: Date = addWeeks(firstWeek.start, weekIndex);
  return makeBoxCol(
    weeksBetween(getMonday(firstOfYear(thisWeekStart)), thisWeekStart) - 1
  );
}

function rowByCalendar(weekIndex: WeekIndex, firstWeek: Week): BoxRow {
  const thisWeekStart: Date = addWeeks(firstWeek.start, weekIndex);
  return makeBoxRow(
    thisWeekStart.getFullYear() - firstWeek.start.getFullYear()
  );
}

export function gridArrangers(
  arrangement: WeekArrangement,
  firstWeek: Week
): [(wi: WeekIndex) => BoxCol, (wi: WeekIndex) => BoxRow] {
  switch (arrangement) {
    case WeekArrangement.ByCalendar:
      return [
        (wi: WeekIndex) => colByCalendar(wi, firstWeek),
        (wi: WeekIndex) => rowByCalendar(wi, firstWeek),
      ];
    case WeekArrangement.ByEvenGrid:
      return [colByEvenGrid, rowByEvenGrid];
    case WeekArrangement.ByAge:
    default:
      throw new Error("not implemented");
  }
}

export function coordArrangers(
  boxCol: (wi: WeekIndex) => BoxCol,
  boxRow: (wi: WeekIndex) => BoxRow
): [
  (wi: WeekIndex) => CoordX, // x
  (wi: WeekIndex) => CoordY, // y
  (wi: WeekIndex) => CoordX, // mid x
  (wi: WeekIndex) => CoordY // mid y
] {
  return [
    (wi: WeekIndex) => xFromBoxCol(boxCol(wi)),
    (wi: WeekIndex) => yFromBoxRow(boxRow(wi)),
    (wi: WeekIndex) => midXFromBoxCol(boxCol(wi)),
    (wi: WeekIndex) => midYFromBoxRow(boxRow(wi)),
  ];
}

export interface AxisLabel {
  text: string;
  key: string;
  forceVisible: boolean;
}

function makeAxisLabel(text: string, arrangement: WeekArrangement): AxisLabel {
  return {
    text: text,
    key: `${arrangement}:${text}`,
    forceVisible: false,
  };
}

export function getXLabels(
  arrangement: WeekArrangement,
  weeks: Week[]
): AxisLabel[] {
  switch (arrangement) {
    case WeekArrangement.ByCalendar:
      const uniqueYears = new Set(weeks.map((w) => w.start.getFullYear()));
      const years: number[] = [...uniqueYears].sort();
      return years.map((y) => makeAxisLabel(`${y}`, arrangement));
    default:
      throw new Error("not implemented");
  }
}

export function getYLabels(
  arrangement: WeekArrangement,
  weeks: Week[]
): AxisLabel[] {
  switch (arrangement) {
    case WeekArrangement.ByCalendar:
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ].map((text) => makeAxisLabel(text, arrangement));
    default:
      throw new Error("not implemented");
  }
}
