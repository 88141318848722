import {
  LIWEvent,
  LIWEntry,
  RawData,
  DateRange,
  TagMetadata,
} from "../common/types";
import { AxisLabel } from "./arrangement";
import { Grid } from "./grids-types";

export interface AppState {
  rawData: RawData;
  settings: AppSettings;
  arrangement: AppCalculatedArrangement;
  calculated: AppCalculatedData;
}

export interface AppSettings {
  weekArrangement: WeekArrangement;
  shownTags: string[];
  detailsPane: AppSettingsDetailsPane;
}

export const enum DetailsPaneKind {
  Ongoing = "ongoing",
  Week = "week",
  Search = "search",
}

export interface DetailsPaneOngoing {
  kind: DetailsPaneKind.Ongoing;
}

export interface DetailsPaneWeek {
  kind: DetailsPaneKind.Week;
  weekIndex: WeekIndex;
}

export interface DetailsPaneSearch {
  kind: DetailsPaneKind.Search;
  term: string;
}

export type AppSettingsDetailsPane =
  | DetailsPaneOngoing
  | DetailsPaneWeek
  | DetailsPaneSearch;

// depends only on dob and arrangement
export interface AppCalculatedArrangement {
  weeks: Week[];
  col(wi: WeekIndex): BoxCol;
  row(wi: WeekIndex): BoxRow;
  x(wi: WeekIndex): CoordX;
  y(wi: WeekIndex): CoordY;
  midX(wi: WeekIndex): CoordX;
  midY(wi: WeekIndex): CoordY;
  xLabels: AxisLabel[];
  yLabels: AxisLabel[];
  weekIndexer(date: Date): WeekIndex;
  gridWidth: BoxCol;
  gridHeight: BoxRow;
}

// depends on rawdata and calculated arrangement
export interface AppCalculatedData {
  entryData: EntryDatum[];
  eventData: EventDatum[];
  cellGrid: Grid<CellDatum>;
  tagMap: TagMap;
  tagEntries: TagMapEntry[];
}

export interface Mutators {
  entry: EntryMutators;
  event: EventMutators;
  setOverallNotes(notes: string): void;
  refresh(): void;
}

export interface EntryMutators {
  setTitle(entry: LIWEntry, title: string): void;
  setTags(entry: LIWEntry, tags: string[]): void;
  setColor(entry: LIWEntry, color: string): void;
  setRanges(entry: LIWEntry, ranges: DateRange[]): void;
  setNotes(entry: LIWEntry, notes: string): void;
  addEntry(initialRange: DateRange): void;
  removeEntry(entry: LIWEntry): void;
  refresh(): void;
}

export interface EventMutators {
  setTitle(event: LIWEvent, title: string): void;
  setTags(event: LIWEvent, tags: string[]): void;
  setBootstrapIcon(event: LIWEvent, bootstrapIcon?: string): void;
  setColor(event: LIWEvent, color?: string): void;
  setNotes(event: LIWEvent, notes: string): void;
  setDate(event: LIWEvent, date: Date): void;
  addEvent(initialDate: Date): void;
  removeEvent(event: LIWEvent): void;
  refresh(): void;
}

export type TagMap = Map<string, TagMapEntry>;

export const enum WeekArrangement {
  ByCalendar = "calendar",
  ByEvenGrid = "grid",
  ByAge = "age",
}

export interface Week {
  // TODO: replace start/end with DateRange?
  readonly start: Date; // Always a Monday
  readonly end: Date; // Always a Sunday
  readonly entries: LIWEntry[];
  readonly events: LIWEvent[];
}

export type WeekIndex = number & { __brand: "WeekIndex" };
export function makeWeekIndex(weekIndex: number): WeekIndex {
  return weekIndex as WeekIndex;
}
/**
 * A box x coording (column 0, column 1, ...)
 */
export type BoxCol = number & { __brand: "BoxCol" };
export function makeBoxCol(weekIndex: number): BoxCol {
  return weekIndex as BoxCol;
}
/**
 * A box y coordinate (row 0, row 1)
 */
export type BoxRow = number & { __brand: "BoxRow" };
export function makeBoxRow(weekIndex: number): BoxRow {
  return weekIndex as BoxRow;
}
/**
 * A X coordinate in svg coords (1, 15, 72.3...)
 */
export type CoordX = number & { __brand: "CoordX" };
export function makeCoordX(weekIndex: number): CoordX {
  return weekIndex as CoordX;
}
/**
 * A Y coordinate in svg coords (1, 15, 72.3...)
 */
export type CoordY = number & { __brand: "CoordY" };
export function makeCoordY(weekIndex: number): CoordY {
  return weekIndex as CoordY;
}

export interface BoxPos {
  readonly x: BoxCol;
  readonly y: BoxRow;
}

// processed data
export interface TagMapEntry {
  tag: string;
  entries: LIWEntry[];
  events: LIWEvent[];
  metadata: TagMetadata | undefined;
  hilite: boolean;
  hiliteFromCell: boolean;
  shown: boolean;
}

//export type Coord = [CoordX, CoordY];
export interface EntryDatum {
  entry: LIWEntry;
  path: string;
  shown: boolean;
  hilite: boolean;
  hiliteFromTag: boolean;
  hiliteFromDetails: boolean;
}

export interface EventDatum {
  event: LIWEvent;
  shown: boolean;
  weekIndex: WeekIndex;
  hilite: boolean;
  hiliteFromTag: boolean;
  hiliteFromDetails: boolean;
}

export type CellGrid = Grid<CellDatum>;

export interface CellDatum {
  week?: Week;
  weekIndex?: WeekIndex;
  entries: EntryDatum[];
  events: EventDatum[];
}
