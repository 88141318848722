import { rebuildEntriesAndEvents, rebuildTags } from "./data";
import { AppState, EntryMutators, EventMutators, Mutators } from "./types";
import { DateRange, LIWEntry, LIWEvent } from "../common/types";

export function getMutators(
  appState: AppState,
  save: () => void,
  refresh: () => void,
  saveSettings: () => void
): Mutators {
  function getEntryMutators(
    appState: AppState,
    refresh: () => void,
    saveSettings: () => void
  ): EntryMutators {
    function setTitle(entry: LIWEntry, title: string): void {
      entry.title = title;
      save();
      refresh();
    }

    function setTags(entry: LIWEntry, tags: string[]): void {
      const oldTags: Set<string> = new Set(entry.tags);
      const newTags = tags.filter((t) => !oldTags.has(t));
      entry.tags = tags;
      appState.calculated = rebuildTags(appState.rawData, appState.calculated);

      if (newTags.length > 0) {
        for (const newTag of newTags) {
          appState.settings.shownTags.push(newTag);
        }
        saveSettings();
      }
      save();
      refresh();
    }

    function setColor(entry: LIWEntry, color: string): void {
      entry.color = color;
      save();
      refresh();
    }

    function setRanges(entry: LIWEntry, ranges: DateRange[]): void {
      // this is heavy... we have to rebuild paths
      entry.ranges = ranges;
      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );
      save();
      refresh();
    }

    function setNotes(entry: LIWEntry, notes: string): void {
      entry.notes = notes;
      // don't think we even actually need to refresh
      //refresh();
    }

    function addEntry(initialRange: DateRange): void {
      const title = getNewTitle(appState.rawData.entries);
      appState.rawData.entries.push({
        title,
        tags: [],
        color: "green",
        notes: "",
        ranges: [initialRange],
      });
      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );
      save();
      refresh();
    }

    function getNewTitle(entries: LIWEntry[]): string {
      const base: string = "Untitled ";
      let num: number = 0;
      let title = `${base} ${num}`;
      while (entries.some((e) => e.title === title)) {
        num += 1;
        title = `${base} ${num}`;
      }

      return title;
    }

    function removeEntry(entry: LIWEntry): void {
      appState.rawData.entries = appState.rawData.entries.filter(
        (e) => e !== entry
      );
      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );

      // TOOD: if this was the last entry with some tags,
      //  shoudl remove it from shown tags maybe?
      appState.calculated = rebuildTags(appState.rawData, appState.calculated);
      save();
      refresh();
    }

    return {
      setTitle,
      setTags,
      setColor,
      setRanges,
      setNotes,
      addEntry,
      removeEntry,
      refresh,
    };
  }

  function getEventMutators(
    appState: AppState,
    refresh: () => void,
    saveSettings: () => void
  ): EventMutators {
    function setTitle(event: LIWEvent, title: string): void {
      event.title = title;
      save();
      refresh();
    }

    function setTags(event: LIWEvent, tags: string[]): void {
      const oldTags: Set<string> = new Set(event.tags);
      const newTags = tags.filter((t) => !oldTags.has(t));
      event.tags = tags;
      appState.calculated = rebuildTags(appState.rawData, appState.calculated);

      if (newTags.length > 0) {
        for (const newTag of newTags) {
          appState.settings.shownTags.push(newTag);
        }
        saveSettings();
      }
      save();
      refresh();
    }

    function setBootstrapIcon(event: LIWEvent, bootstrapIcon?: string): void {
      event.bootstrapIcon = bootstrapIcon;
      save();
      refresh();
    }

    function setColor(event: LIWEvent, color?: string): void {
      event.color = color;
      save();
      refresh();
    }

    function setNotes(event: LIWEvent, notes: string): void {
      event.notes = notes;
      save();
    }

    function setDate(event: LIWEvent, date: Date): void {
      // this is heavy... we have to rebuild paths
      event.date = date;
      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );
      save();
      refresh();
    }

    function addEvent(initialDate: Date): void {
      const title = getNewEventTitle(appState.rawData.events);
      appState.rawData.events.push({
        title,
        tags: [],
        color: "green",
        notes: "",
        date: initialDate,
      });

      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );
      save();
      refresh();
    }

    function getNewEventTitle(events: LIWEvent[]): string {
      const base: string = "Untitled ";
      let num: number = 0;
      let title = `${base} ${num}`;
      while (events.some((e) => e.title === title)) {
        num += 1;
        title = `${base} ${num}`;
      }

      return title;
    }

    function removeEvent(event: LIWEvent): void {
      appState.rawData.events = appState.rawData.events.filter(
        (e) => e !== event
      );

      appState.calculated = rebuildEntriesAndEvents(
        appState.rawData.entries,
        appState.rawData.events,
        appState.arrangement,
        appState.calculated
      );

      // TOOD: if this was the last event with some tags,
      //  should remove it from shown tags maybe?
      appState.calculated = rebuildTags(appState.rawData, appState.calculated);
      save();
      refresh();
    }

    return {
      setTitle,
      setTags,
      setBootstrapIcon,
      setColor,
      setNotes,
      setDate,
      addEvent,
      removeEvent,
      refresh,
    };
  }

  return {
    entry: getEntryMutators(appState, refresh, saveSettings),
    event: getEventMutators(appState, refresh, saveSettings),
    setOverallNotes(notes: string) {
      appState.rawData.overallNotes = notes;
      save();
      refresh();
    },
    refresh,
  };
}
