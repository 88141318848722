import {
  BoxCol,
  BoxRow,
  CoordX,
  CoordY,
  EntryDatum,
  TagMapEntry,
  WeekIndex,
  CellDatum,
  AppSettings,
  AppState,
  WeekArrangement,
  DetailsPaneKind,
  EventDatum,
} from "./types";
import {
  RawData,
  RawDataFromJson,
  WithDatesAsStrings,
  DateRange,
  LoadResponse,
} from "../common/types";
import {
  sizes,
  AxisLabel,
  boxCoords,
  boxColFromX,
  boxRowFromY,
  weekIndexFromDateAndStartDate,
} from "./arrangement";
import * as detailsPane from "./details-pane";
import { allTags, applyShownTags, buildAppState } from "./data";
import { getMutators } from "./data-mutation";
import {
  Autosaver,
  AutosaverState,
  AutosaverStateChangeEvent,
} from "./autosaver";

// export data for console
declare global {
  interface Window {
    appState: AppState;
  }
}

function revivifyRawData(raw: RawDataFromJson): RawData {
  // parse dates on everything that should be a date
  return {
    dateOfBirth: new Date(Date.parse(raw.dateOfBirth)),
    tags: raw.tags,
    entries: raw.entries.map((e) => ({
      title: e.title,
      tags: e.tags,
      color: e.color,
      ranges: e.ranges.map((r) => revivifyRange(r)),
      notes: e.notes,
    })),
    events: raw.events.map((e) => ({
      title: e.title,
      tags: e.tags,
      color: e.color,
      bootstrapIcon: e.bootstrapIcon,
      date: new Date(Date.parse(e.date)),
      notes: e.notes,
    })),
    overallNotes: raw.overallNotes,
  };
}

function revivifyRange(r: WithDatesAsStrings<DateRange>): DateRange {
  return {
    from: new Date(Date.parse(r.from)),
    to: r.to === undefined ? undefined : new Date(Date.parse(r.to)),
  };
}

function getDateOfBirth(): Date | undefined {
  let first: boolean = true;
  let dob: Date | undefined = undefined;

  while (dob === undefined) {
    const input: string | null = prompt(
      first
        ? "Enter your date of birth to start."
        : "Invalid input.\nEnter your date of birth to start."
    );
    first = false;

    if (input === null) {
      // canceled
      return undefined;
    }

    const dateNum: number = Date.parse(input);
    if (!isNaN(dateNum)) {
      dob = new Date(dateNum);
    }
  }

  return dob;
}

// anonymous function limits the umd global warning to a single place
((d3: typeof window.d3) => {
  window.onload = async () => {
    console.log(`D3 Version ${d3.version}`);

    // prep data
    const appSettings: AppSettings = loadSettings();

    const loadResponse: LoadResponse = (await d3.json("/load")) as LoadResponse;
    if (loadResponse.user === undefined) {
      // if not authenticated, authenticate
      console.log("not authorized, set location to /auth");
      window.location.href = "/auth";
      return;
    }

    if (loadResponse.user.imageSrc !== undefined) {
      d3.select("#details-user-image").attr("src", loadResponse.user.imageSrc);
    }

    const rawData: RawData | undefined =
      loadResponse.data === undefined
        ? (() => {
            if (loadResponse.data === undefined) {
              const dob: Date | undefined = getDateOfBirth();
              if (dob === undefined) {
                // canceled.
                return undefined;
              }

              return {
                dateOfBirth: dob,
                tags: {},
                entries: [],
                events: [],
                overallNotes: "",
              };
            }
          })()
        : (() => {
            return revivifyRawData(loadResponse.data);
          })();

    if (rawData == undefined) {
      return;
    }

    // Default details pane to current week
    appSettings.detailsPane = {
      kind: DetailsPaneKind.Week,
      weekIndex: weekIndexFromDateAndStartDate(new Date(), rawData.dateOfBirth),
    };

    // Default for tags shown is [],
    //   reset it to all tags.
    if (appSettings.shownTags.length === 0) {
      appSettings.shownTags = allTags(rawData);
    }

    let appState = buildAppState(rawData, appSettings);
    const mutators = getMutators(
      appState,
      () => {
        autosaver.modified();
      },
      () => {
        refresh();
      },
      saveSettings
    );
    window.appState = appState;

    function doSave(): Promise<string> {
      const toSave: string = JSON.stringify(appState.rawData, null, 2);
      return d3.text("/save", {
        method: "POST",
        body: toSave,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }

    const autosaver = new Autosaver(doSave, {
      debounceMs: 5000,
      onStateChange(e: AutosaverStateChangeEvent) {
        let text = "";
        switch (e.state) {
          case AutosaverState.Modified:
            text = "Modified";
            break;
          case AutosaverState.Saving:
            text = "Saving";
            break;
          case AutosaverState.Saved:
            text = `Last saved ${new Date().toLocaleTimeString()}`;
            break;
        }
        d3.select("#details-save-text").text(text);
      },
    });

    // Warn the user if they navigate away and there is unsaved data
    window.onbeforeunload = function (e) {
      if (autosaver.saveIsPending()) {
        return "There is unsaved data - are you sure you want to close?";
      }
    };

    // Make Ctrl+S trigger an immediate save
    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        autosaver.immediate();
      }
    });

    // for convenience
    const arr = appState.arrangement;

    let lastHoveredCol: BoxCol | undefined;
    let lastHoveredRow: BoxRow | undefined;

    function overallMouseMove(e: MouseEvent) {
      const [svgX, svgY] = d3.pointer(e);
      const thisCol = boxColFromX(svgX as CoordX);
      const thisRow = boxRowFromY(svgY as CoordY);
      if (thisCol !== lastHoveredCol || thisRow !== lastHoveredRow) {
        // hovered cell changed
        if (lastHoveredCol !== undefined && lastHoveredRow !== undefined) {
          const d =
            appState.calculated.cellGrid[lastHoveredCol][lastHoveredRow];
          overallMouseLeaveCell(e, d, lastHoveredCol, lastHoveredRow);
        }

        if (
          thisCol >= 0 &&
          thisCol < arr.gridWidth &&
          thisRow >= 0 &&
          thisRow < arr.gridHeight
        ) {
          const d = appState.calculated.cellGrid[thisCol][thisRow];
          lastHoveredCol = thisCol;
          lastHoveredRow = thisRow;
          overallMouseEnterCell(e, d, thisCol, thisRow);
        } else {
          lastHoveredCol = undefined;
          lastHoveredRow = undefined;
        }
      }
    }

    function hideSearchTermInput() {
      d3.select<HTMLInputElement, never>("input#details-search-term").style(
        "display",
        "none"
      );
    }

    function showOngoingDetailsPane() {
      appState.settings.detailsPane = {
        kind: DetailsPaneKind.Ongoing,
      };
      hideSearchTermInput();
      refreshDetailsPane();
    }

    function showWeekDetailsPane(weekIndex: WeekIndex) {
      appState.settings.detailsPane = {
        kind: DetailsPaneKind.Week,
        weekIndex: weekIndex,
      };
      hideSearchTermInput();
      refreshDetailsPane();
    }

    function showSearchDetailsPane(term: string) {
      appState.settings.detailsPane = {
        kind: DetailsPaneKind.Search,
        term,
      };
      refreshDetailsPane();
    }

    function refreshDetailsPane() {
      const dp = appState.settings.detailsPane;
      switch (dp.kind) {
        case DetailsPaneKind.Week:
          const thisCol = appState.arrangement.col(dp.weekIndex);
          const thisRow = appState.arrangement.row(dp.weekIndex);

          const d = appState.calculated.cellGrid[thisCol][thisRow];

          if (d.week !== undefined) {
            contextDetailsRange = {
              from: d.week.start,
              to: d.week.end,
            };

            detailsPane.showWeek(d.week, d.entries, d.events, mutators);
          }

          break;
        case DetailsPaneKind.Ongoing:
          const openEntries = appState.calculated.entryData.filter(
            (e) =>
              e.entry.ranges.length === 0 ||
              e.entry.ranges.some((r) => r.to === undefined)
          );
          detailsPane.showOpen(openEntries, [], mutators);
          break;
        case DetailsPaneKind.Search:
          const term = dp.term.toUpperCase();
          const matchedEntries = appState.calculated.entryData.filter(
            (e) =>
              e.entry.title.toUpperCase().includes(term) ||
              e.entry.tags.some((t) => t.toUpperCase().includes(term)) ||
              e.entry.notes.toUpperCase().includes(term) ||
              e.entry.color?.toUpperCase().includes(term)
          );
          const matchedEvents = appState.calculated.eventData.filter(
            (e) =>
              e.event.title.toUpperCase().includes(term) ||
              e.event.tags.some((t) => t.toUpperCase().includes(term)) ||
              e.event.notes.toUpperCase().includes(term) ||
              e.event.color?.toUpperCase().includes(term)
          );
          detailsPane.showSearch(
            dp.term,
            matchedEntries,
            matchedEvents,
            mutators
          );
      }
    }

    let contextDetailsRange: DateRange | undefined;
    function overallClick(e: MouseEvent) {
      const [svgX, svgY] = d3.pointer(e);
      const thisCol = boxColFromX(svgX as CoordX);
      const thisRow = boxRowFromY(svgY as CoordY);

      if (
        thisCol >= 0 &&
        thisCol < arr.gridWidth &&
        thisRow >= 0 &&
        thisRow < arr.gridHeight
      ) {
        const d = appState.calculated.cellGrid[thisCol][thisRow];

        if (d.weekIndex !== undefined) {
          showWeekDetailsPane(d.weekIndex);

          selectedRect
            .attr("x", arr.x(d.weekIndex))
            .attr("y", arr.y(d.weekIndex))
            .attr("visible", "");
        } else {
          selectedRect.attr("visible", "hidden");
        }
      }
    }

    d3.select("button#details-show-open").on("click", () =>
      showOngoingDetailsPane()
    );

    d3.select("button#details-search").on("click", () => {
      d3.select<HTMLInputElement, never>("input#details-search-term")
        .style("display", "")
        .node()
        ?.focus();
    });

    d3.select("input#details-search-term").on("input", (e: Event) => {
      //console.log("search term input", e);
      const newTerm = (e.target as HTMLInputElement).value;
      showSearchDetailsPane(newTerm);
    });

    d3.select("button#details-new-entry").on("click", () => {
      // context of selected range, otherwise week including today?
      const initialDateRange =
        contextDetailsRange !== undefined &&
        !inRange(contextDetailsRange, today)
          ? Object.assign(contextDetailsRange, { to: undefined })
          : (() => {
              const todayWeek = appState.arrangement.weeks[todayWeekIndex];
              return {
                from: today,
                to: undefined,
              };
            })();
      mutators.entry.addEntry(initialDateRange);
    });

    d3.select("button#details-new-event").on("click", () => {
      // context of selected range, otherwise week including today?
      const initialDate: Date =
        contextDetailsRange !== undefined &&
        !inRange(contextDetailsRange, today)
          ? contextDetailsRange?.from
          : new Date();
      mutators.event.addEvent(initialDate);
    });
    // d3.select("button#test").on("click", async () => {});

    function overallMouseEnterCell(
      e: MouseEvent,
      d: CellDatum,
      boxCol: BoxCol,
      boxRow: BoxRow
    ) {
      const tagSet = new Set<string>();
      for (const entry of d.entries) {
        entry.hilite = true;
        for (const tag of entry.entry.tags) {
          tagSet.add(tag);
        }
      }

      for (const event of d.events) {
        event.hilite = true;
        for (const tag of event.event.tags) {
          tagSet.add(tag);
        }
      }

      appState.calculated.tagEntries
        .filter((t) => tagSet.has(t.tag))
        .map((t) => (t.hiliteFromCell = true));

      refresh();
    }

    function overallMouseLeaveCell(
      e: MouseEvent,
      d: CellDatum,
      boxCol: BoxCol,
      boxRow: BoxRow
    ) {
      const tagSet = new Set<string>();
      for (const entry of d.entries) {
        entry.hilite = false;
        for (const tag of entry.entry.tags) {
          tagSet.add(tag);
        }
      }

      for (const event of d.events) {
        event.hilite = false;
        for (const tag of event.event.tags) {
          tagSet.add(tag);
        }
      }

      appState.calculated.tagEntries
        .filter((t) => tagSet.has(t.tag))
        .map((t) => (t.hiliteFromCell = false));

      refresh();
    }

    const svg = d3
      .select("#main")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("pointer-events", "all")
      .attr("cursor", "pointer")
      .on("mousemove", overallMouseMove)
      .on("click", overallClick);

    const rects = svg
      .append("g")
      .attr("id", "boxes")
      .selectAll("rect")
      .data(arr.weeks)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("width", sizes.boxWidth)
            .attr("height", sizes.boxHeight)
            .attr("stroke", "black")
            .attr("stroke-width", sizes.boxStrokeWidth)
            .attr("fill", "transparent"),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr("x", (_d, i) => arr.x(i as WeekIndex))
      .attr("y", (_d, i) => arr.y(i as WeekIndex));

    rects
      .append("title")
      .text(
        (d) => `${d.start.toLocaleDateString()} - ${d.end.toLocaleDateString()}`
      );

    const xLabelTexts = svg
      .append("g")
      .attr("id", "xLabels")
      .selectAll("text")
      .data(arr.xLabels, (d) => (d as AxisLabel).key)
      .join(
        (enter) =>
          enter
            .append("text")
            .text((d) => d.text)
            .attr("text-anchor", "end")
            .attr("alignment-baseline", "hanging")
            .attr("font-size", `12px`),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr("x", sizes.marginLeft - sizes.boxHorizontalSpacing * 3)
      .attr("y", (_d, i) => boxCoords.top(i as BoxRow))
      .attr("visibility", (d, i) =>
        d.forceVisible || i % 3 == 0 ? "" : "hidden"
      );

    const yLabelTexts = svg
      .append("g")
      .attr("id", "yLabels")
      .selectAll("text")
      .data(arr.yLabels, (d) => (d as AxisLabel).key)
      .join(
        (enter) =>
          enter
            .append("text")
            .text((d) => d.text)
            .attr("text-anchor", "middle")
            .attr("font-size", "12px"),
        (update) => update,
        (exit) => exit.remove()
      )
      .attr("x", (_d, i) => boxCoords.left(((52 / 12) * (i + 0.5)) as BoxCol))
      .attr("y", sizes.marginTop - sizes.boxVerticalSpacing * 3);

    function toggleTagShown(d: TagMapEntry) {
      d.shown = !d.shown;
      if (d.shown) {
        appSettings.shownTags.push(d.tag);
      } else {
        appSettings.shownTags = appSettings.shownTags.filter(
          (t) => t !== d.tag
        );
      }
      saveSettings();
      applyShownTags(appState.settings, appState.calculated);
      refresh();
    }

    function saveSettings() {
      localStorage.setItem("settings", JSON.stringify(appState.settings ?? ""));
    }

    function loadSettings(): AppSettings {
      const saved: string | null = localStorage.getItem("settings");

      return saved == null
        ? {
            weekArrangement: WeekArrangement.ByCalendar,
            shownTags: [],
            detailsPane: {
              kind: DetailsPaneKind.Ongoing,
            },
          }
        : (JSON.parse(saved) as AppSettings);
    }

    const tagButtonBgColor = colorOrDefault("lightgray");

    let refreshTimeoutID: number | undefined;
    // todo: a way to recalc paths? maybe a class for entry data or somehting?
    // probably actually just split to library and stay more functional
    function refresh() {
      if (refreshTimeoutID === undefined) {
        setTimeout(() => {
          refreshTimeoutID = undefined;
          applyData();
          refreshDetailsPane();
        });
      }
    }

    refresh();

    const entryPathsG = svg.append("g").attr("id", "entryPaths");
    const eventsG = svg.append("g").attr("id", "events");

    const today = new Date(new Date().toDateString()); // Just the date part
    const todayWeekIndex = arr.weekIndexer(today);

    const selectedG = svg.append("g").attr("id", "selected");

    const selectedRect = selectedG
      .append("rect")
      .attr("id", "selectedRect")
      .attr("width", sizes.boxFullWidth)
      .attr("height", sizes.boxFullHeight)
      .attr("stroke", "yellow")
      .attr("stroke-width", sizes.boxStrokeWidth)
      .attr("fill", "transparent")
      .attr("x", arr.x(todayWeekIndex))
      .attr("y", arr.y(todayWeekIndex))
      .attr("visible", "hidden");

    //refreshDetailsPane();

    function applyData(): void {
      const entryData = appState.calculated.entryData;
      const eventData = appState.calculated.eventData;
      const tagMapEntries = appState.calculated.tagEntries;

      d3.select("#overall-notes")
        .on("change", (e: Event) => {
          mutators.setOverallNotes((e.target as HTMLTextAreaElement).value);
        })
        .text(appState.rawData.overallNotes);

      const entryPaths = entryPathsG
        .selectAll<SVGPathElement, EntryDatum>("path")
        .data(entryData, (d: EntryDatum) => d.entry.title)
        .join(
          (enter) =>
            enter
              .append("path")
              .attr("fill-rule", "evenodd")
              .style("transition", "ease-in-out,fill-opacity .15s")
              .attr("stroke", "black"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("d", (d) => d.path)
        .attr("stroke-width", (d) => (d.hiliteFromDetails ? 1 : 0))
        .attr("fill", (d) =>
          d.hilite || d.hiliteFromTag || d.hiliteFromDetails
            ? d3
                .color(d.entry.color ?? "gray")!
                .brighter()
                .toString()
            : d.entry.color ?? "gray"
        )
        .attr("fill-opacity", (d) => (d.shown ? 0.5 : 0));

      const tagButtons = d3
        .select("#tags")
        .selectAll<HTMLButtonElement, TagMapEntry>("button.tag")
        .data(tagMapEntries, (d: TagMapEntry) => "" + d.tag)
        .join(
          (enter) =>
            enter
              .append("button")
              .classed("tag", true)
              .style("border-color", (d) =>
                d3
                  .color(tagButtonBgColor(d.metadata?.color))!
                  .darker()
                  .toString()
              )

              .on("mouseenter", function (e: MouseEvent, d: TagMapEntry) {
                d.hilite = true;
                entryData
                  .filter((e) => e.entry.tags.includes(d.tag))
                  .map((e) => (e.hiliteFromTag = true));
                eventData
                  .filter((e) => e.event.tags.includes(d.tag))
                  .map((e) => (e.hiliteFromTag = true));
                refresh();
              })
              .on("mouseleave", function (e: MouseEvent, d: TagMapEntry) {
                d.hilite = false;
                entryData
                  .filter((e) => e.entry.tags.includes(d.tag))
                  .map((e) => (e.hiliteFromTag = false));
                eventData
                  .filter((e) => e.event.tags.includes(d.tag))
                  .map((e) => (e.hiliteFromTag = false));
                refresh();
              })
              .on("click", function (e: MouseEvent, d: TagMapEntry) {
                toggleTagShown(d);
              }),
          (update) => update,
          (exit) => exit.remove()
        )
        .style("background-color", (d) =>
          d.shown
            ? d.hilite || d.hiliteFromCell
              ? d3
                  .color(tagButtonBgColor(d.metadata?.color))!
                  .brighter()
                  .toString()
              : tagButtonBgColor(d.metadata?.color)
            : "ghostwhite"
        )
        .style("color", (d) =>
          d.shown
            ? contrastingBlackorWhite(
                tagButtonBgColor(d.metadata?.color)
              ).toString()
            : "black"
        )
        .text((d) => d.tag);

      const iconSize: number = d3.min([sizes.boxHeight, sizes.boxWidth])!;

      const eventThings = eventsG
        .selectAll<SVGGElement, EventDatum>("use")
        .data(appState.calculated.eventData, (d: EventDatum) => d.event.title)
        .join(
          (enter) =>
            enter
              .append("use")
              .attr("width", iconSize)
              .attr("height", iconSize),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("color", (d) =>
          d.hilite || d.hiliteFromTag || d.hiliteFromDetails
            ? d3
                .color(d.event.color ?? "black")!
                .brighter()
                .toString()
            : d.event.color ?? "black"
        )
        .attr("fill", "currentColor")
        .attr(
          "href",
          (d) => `bootstrap-icons.svg#${d.event.bootstrapIcon ?? "circle-fill"}`
        )
        .attr(
          "x",
          (d) => arr.x(d.weekIndex) + sizes.boxWidth / 2 - iconSize / 2
        )
        .attr(
          "y",
          (d) => arr.y(d.weekIndex) + sizes.boxHeight / 2 - iconSize / 2
        );
    }
  };

  function colorOrDefault(
    defaultColor: string
  ): (color: string | undefined) => string {
    return (c) => c ?? defaultColor;
  }

  function contrastingBlackorWhite(color: string): string {
    return d3.hsl(color).l > 0.5 ? "#000" : "#fff";
  }

  function inRange(range: DateRange, date: Date): boolean {
    return date >= range.from && date <= (range.to ?? date);
  }
})(d3);
