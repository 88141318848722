/**
 *  Handling for grids
 */

import { BoxCol, BoxPos, BoxRow } from "./types";
import { Grid, DebugGrid, DebugCellDepth, Positioners } from "./grids-types";

let _tracingEnabled = false;

export function tracingEnabled(value?: boolean): boolean {
  if (value !== undefined) {
    _tracingEnabled = value;
  }
  return _tracingEnabled;
}

function traceLog(...args: unknown[]) {
  if (_tracingEnabled) {
    console.log(args);
  }
}

function gridHeight<T>(grid: Grid<T>) {
  return grid[0].length;
}

function gridWidth<T>(grid: Grid<T>) {
  return grid.length;
}

const enum CellState {
  Zero = 0,
  One = 1,
  TracingStart = 2,
  TracingEdge = 3,
  Traced = 4,
  Outside = 5,
  FillQueued = 6,
  Filled = 7,
}

export function traceIslands(
  grid: Grid<boolean>,
  coords: Positioners
): [string, DebugGrid?] {
  let path = "";
  let pos: BoxPos;

  const cellGrid: Grid<CellState> = makeGrid(
    gridWidth(grid),
    gridHeight(grid),
    (x, y) => (grid[x][y] ? CellState.One : CellState.Zero)
  );

  let debugGrid: DebugGrid | undefined;
  if (_tracingEnabled) {
    debugGrid = makeDebugGrid(grid);
  }

  for (let y: number = 0; y < grid.length; y++) {
    for (let x: number = 0; x < grid.length; x++) {
      pos = {
        x: x as BoxCol,
        y: y as BoxRow,
      };

      printDebugGrid("traceIslands", debugGrid, pos);
      // if (grid[x][y] == CellState.Zero) {
      //   continue;
      // }

      // if (grid[x][y] == CellState.One) {
      //   // trace around the edge, somewhat recursively
      //   path += traceIsland(grid, x, y);
      // }
      if (cellGrid[x][y] != CellState.One) {
        continue;
      }

      // trace around the edge, somewhat recursively
      path += traceIsland(
        cellGrid,
        pos,
        CellState.One,
        CellState.Zero,
        coords,
        1,
        debugGrid
      );
    }
  }

  return [path, debugGrid];
}

const enum Direction {
  Right = 0,
  Down = 1,
  Left = 2,
  Up = 3,
}

function add(pos: BoxPos, dir: Direction): BoxPos {
  return {
    x: addX(pos.x, dir),
    y: addY(pos.y, dir),
  };
}

function addX(x: BoxCol, dir: Direction): BoxCol {
  switch (dir) {
    case Direction.Right:
      return (x + 1) as BoxCol;
    case Direction.Left:
      return (x - 1) as BoxCol;
    default:
      return x;
  }
}

function addY(y: BoxRow, dir: Direction): BoxRow {
  switch (dir) {
    case Direction.Up:
      return (y - 1) as BoxRow;
    case Direction.Down:
      return (y + 1) as BoxRow;
    default:
      return y;
  }
}

function cw(dir: Direction): Direction {
  return ((dir + 1) % 4) as Direction;
}

function ccw(dir: Direction): Direction {
  return ((dir + 3) % 4) as Direction;
}

function traceIsland(
  grid: Grid<CellState>,
  pos: BoxPos,
  target: CellState,
  outside: CellState,
  coords: Positioners,
  depth: number,
  debugGrid?: DebugGrid
): string {
  // x, y is an upper left corner.
  // plan:
  // 1) trace the outer path, marking the outer edge
  // 2) flood fill island. any bordering 0s are an inner edge
  // 3) trace any inner edges recursively

  grid[pos.x][pos.y] = CellState.TracingStart;
  // maybe an optional direction...
  let path = ` M ${coords.left(pos.x)} ${coords.bottom(pos.y)}`;

  //path += ` V ${pos.top(y)} H ${pos.right(x)}`;

  // tries
  // do the tries without the probe. if no hits, add a Z
  // try all 4 directions
  // let dir: Direction = Direction.Left;
  // let subPath: string = "";
  // let hit: boolean = false;
  // let anyHit: boolean = false;

  printDebugGrid("traceIsland (start)", debugGrid, pos);

  path += trySteps(
    grid,
    pos,
    target,
    outside,
    coords,
    Direction.Left,
    debugGrid
  );

  path += floodFill(
    grid,
    pos,
    [CellState.TracingStart, CellState.TracingEdge, target],
    CellState.Traced,
    outside,
    target,
    coords,
    depth,
    debugGrid
  );

  return path;
}

function floodFill(
  grid: Grid<CellState>,
  pos: BoxPos,
  toFill: CellState[],
  fillWith: CellState,
  triggerTarget: CellState,
  triggeredTraceOutside: CellState,
  coords: Positioners,
  depth: number,
  debugGrid?: DebugGrid
): string {
  grid[pos.x][pos.y] = fillWith;
  let insidePaths: string = "";

  // TODO:
  // need to change this so we flood fill everythign and THEN try potential inside tracing
  // also, i think direction we approach it matters for direction the should start...? maybe not as long as we flood fill everythign first
  // maybe keep an open set/queue instead of being recursive here to do that

  const fillQueue: BoxPos[] = []; // expanding front of flood fill to fill
  const toTrace: BoxPos[] = []; // "inside" outside edges, which means a new island to trace

  fillQueue.push(pos);

  while (fillQueue.length !== 0) {
    const p: BoxPos = fillQueue.shift()!;

    printDebugGrid("floodFill", debugGrid, p);

    grid[p.x][p.y] = CellState.Filled;
    if (debugGrid !== undefined) {
      debugGrid[p.x][p.y] = debugDepth(depth);
    }

    for (const dir of [
      Direction.Left,
      Direction.Up,
      Direction.Right,
      Direction.Down,
    ]) {
      const newPos = add(p, dir);

      const contents: CellState | undefined = probe(grid, newPos);

      if (contents === undefined) {
        continue;
      }

      if (toFill.indexOf(contents) !== -1) {
        fillQueue.push(newPos);
        grid[newPos.x][newPos.y] = CellState.FillQueued;
      } else if (contents === triggerTarget) {
        setAdd(toTrace, newPos);
      }
    }
  }

  // The island is flood filled. Now, we need to trace any inner edges.
  while (toTrace.length !== 0) {
    const p: BoxPos = toTrace.shift()!;
    // double check, it may have been trace as part of an inside island we already traced
    if (grid[p.x][p.y] === triggerTarget) {
      insidePaths += traceIsland(
        grid,
        p,
        triggerTarget,
        triggeredTraceOutside,
        coords,
        depth + 1,
        debugGrid
      );
    }
  }
  traceLog("returning insidePaths ", insidePaths);

  return insidePaths;
}

function probe<T>(grid: Grid<T>, pos: BoxPos): T | undefined {
  const width = gridWidth(grid);
  const height = gridHeight(grid);

  // bounds check
  if (pos.x < 0 || pos.x >= width || pos.y < 0 || pos.y >= height) {
    return undefined;
  }

  return grid[pos.x][pos.y];
}

// for testing purposes, might make sense to allow building islands as sets of pos
function trySteps(
  grid: Grid<CellState>,
  pos: BoxPos,
  target: CellState,
  outside: CellState,
  coords: Positioners,
  firstDir: Direction,
  debugGrid?: DebugGrid
): string {
  let addPath = "";

  let dir: Direction = firstDir;
  let subPath: string = "";
  let hit: boolean = false;

  // try all 4 directions
  for (let i: number = 0; i < 4; i++) {
    [subPath, hit] = tryStep(
      dir,
      grid,
      add(pos, dir),
      target,
      outside,
      coords,
      debugGrid
    );
    if (hit) {
      return addPath + subPath;
    } else {
      traceLog(`trySteps from ${dir} missed`);
    }

    dir = cw(dir);
    addPath += pathMove(pos, dir, coords);
  }

  // only way to reach here is if we're trying to step from the first island - there are no adjacent connected bits
  return addPath;
}

function tryStep(
  direction: Direction,
  grid: Grid<CellState>,
  pos: BoxPos,
  target: CellState,
  outside: CellState,
  coords: Positioners,
  debugGrid?: DebugGrid
): [string, boolean] {
  let addPath: string = "";

  // bounds check
  const contents: CellState | undefined = probe(grid, pos);

  if (contents == outside) {
    grid[pos.x][pos.y] = CellState.Outside;
    if (debugGrid !== undefined) {
      debugGrid[pos.x][pos.y] = "o";
    }
    return ["", false];
  }

  if (
    contents != target &&
    contents != CellState.TracingEdge &&
    contents != CellState.TracingStart
  ) {
    return ["", false];
  }

  printDebugGrid(`tryStep (hit) from ${direction}`, debugGrid, pos);

  if (contents == CellState.TracingStart) {
    // awkward base condition

    // technically only possible for left and up
    // if up, we're done. if left, we still need to check down
    if (direction === Direction.Up) {
      return [" Z", true];
    } else if (direction === Direction.Left) {
      const [subPath, hit] = tryStep(
        Direction.Down,
        grid,
        add(pos, Direction.Down),
        target,
        outside,
        coords,
        debugGrid
      );
      return [
        hit ? ` ${subPath}` : ` ${pathMove(pos, Direction.Left, coords)} Z`,
        true,
      ];
    } else {
      throw new Error("not implemented");
    }
  }
  // return [" Z", true];

  grid[pos.x][pos.y] = CellState.TracingEdge;

  addPath += trySteps(
    grid,
    pos,
    target,
    outside,
    coords,
    ccw(direction),
    debugGrid
  );

  return [addPath, true];
  // let dir = ccw(direction);

  // // try all 4 directions
  // for (let i: number = 0; i < 4; i++) {
  //   [subPath, hit] = tryStep(dir, grid, add(pos, dir), target, outside, coords);
  //   if (hit) {
  //     return [addPath + subPath, true];
  //   }

  //   dir = cw(dir);
  //   addPath += pathMove(pos, dir, coords);
  // }

  // throw new Error(
  //   "should be unreachable - at the very least, will hit the way we came"
  // );
}

function pathMove(pos: BoxPos, dir: Direction, coords: Positioners) {
  switch (dir) {
    case Direction.Right:
      return ` H ${coords.right(pos.x)}`;
    case Direction.Down:
      return ` V ${coords.bottom(pos.y)}`;
    case Direction.Left:
      return ` H ${coords.left(pos.x)}`;
    case Direction.Up:
      return ` V ${coords.top(pos.y)}`;
    default:
      throw new Error("bad direction");
  }
}

// only add if it isn't already in the collection
function setAdd(positions: BoxPos[], pos: BoxPos) {
  if (!setContains(positions, pos)) {
    positions.push(pos);
  }
}

function setContains(positions: BoxPos[], pos: BoxPos) {
  return positions.some((p) => p.x === pos.x && p.y === pos.y);
}

function debugDepth(depth: number): DebugCellDepth {
  if (depth <= 0) {
    return " ";
  } else if (depth <= 10) {
    return depth.toString() as DebugCellDepth;
  }
  return "D";
}

function copyGrid<T>(grid: Grid<T>): Grid<T> {
  return makeGrid(gridWidth(grid), gridHeight(grid), (x, y) => grid[x][y]);
}

function makeDebugGrid(grid: Grid<unknown>): DebugGrid {
  return makeGrid(gridWidth(grid), gridHeight(grid), " ");
}

function debugGridWithPos(grid: DebugGrid, pos: BoxPos): DebugGrid {
  const newGrid: DebugGrid = copyGrid(grid);
  newGrid[pos.x][pos.y] = "*";
  return newGrid;
}

export function debugGridRows(grid: DebugGrid): string[] {
  const width = gridWidth(grid);
  const height = gridHeight(grid);

  const rows: string[] = [];
  for (let y: number = 0; y < height; y++) {
    let row = "";
    for (let x: number = 0; x < width; x++) {
      row += grid[x][y];
    }
    rows.push(row);
  }

  return rows;
}

export function debugGridString(grid: DebugGrid): string {
  const width = gridWidth(grid);
  const height = gridHeight(grid);
  let ret = "";
  ret += `+${repeat("-", width)}+\n`;
  for (let y: number = 0; y < height; y++) {
    let row = "";
    for (let x: number = 0; x < width; x++) {
      row += grid[x][y];
    }
    ret += `|${row}|\n`;
  }
  ret += `+${repeat("-", width)}+`;

  return ret;
}

function printDebugGrid(title: string, grid?: DebugGrid, pos?: BoxPos) {
  if (grid === undefined) {
    return;
  }
  traceLog(title);
  traceLog(
    debugGridString(pos === undefined ? grid : debugGridWithPos(grid, pos))
  );
}

function repeat(str: string, count: number) {
  let ret = "";
  for (let i: number = 0; i < count; i++) {
    ret += str;
  }
  return ret;
}

export function makeGrid<T>(
  width: number,
  height: number,
  fillFunction: (x: number, y: number) => T
): Grid<T>;
export function makeGrid<T>(
  width: number,
  height: number,
  fillValue: T
): Grid<T>;
export function makeGrid<T>(
  width: number,
  height: number,
  fillFunctionOrValue: (x: number, y: number) => T | T
): Grid<T> {
  const fillFunction: (x: number, y: number) => T =
    typeof fillFunctionOrValue === "function"
      ? fillFunctionOrValue
      : (_x, _y) => fillFunctionOrValue;

  if (typeof fillFunctionOrValue === "function") {
  }
  const cols: T[][] = new Array<T[]>(width);
  for (let x: number = 0; x < width; x++) {
    cols[x] = new Array<T>(height);
    for (let y: number = 0; y < height; y++) {
      cols[x][y] = fillFunction(x, y);
    }
  }

  return cols;
}
