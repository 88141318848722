const msPerDay: number = 1000 * 60 * 60 * 24;

export function withoutTime(date: Date) {
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

const enum DayOfWeek { // matches Date.getDay() values
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

// Gets the monday before, or the date if it's a monday
export function getMonday(date: Date): Date {
  const d = withoutTime(date);
  const dayAdjustment: number = (d.getDay() - DayOfWeek.Monday + 7) % 7;
  return new Date(d.setDate(d.getDate() - dayAdjustment));
}

export function firstOfYear(date: Date): Date {
  const d = withoutTime(date);
  d.setMonth(0);
  d.setDate(0);
  return d;
}

export function daysBetween(fromDate: Date, toDate: Date): number {
  // Math.round to deal with UTC
  return Math.round(
    (withoutTime(toDate).getTime() - withoutTime(fromDate).getTime()) / msPerDay
  );
}

export function weeksBetween(fromDate: Date, toDate: Date): number {
  return Math.floor(daysBetween(fromDate, toDate) / 7);
}

export function yearsBetween(fromDate: Date, toDate: Date): number {
  return toDate.getFullYear() - fromDate.getFullYear();
}

export function addDays(toDate: Date, days: number): Date {
  const d = new Date(toDate);
  d.setDate(d.getDate() + days);
  return d;
}

export function addWeeks(toDate: Date, weeks: number): Date {
  return addDays(toDate, 7 * weeks);
}
