export const enum AutosaverState {
  Initial = "initial",
  Modified = "modified",
  Saving = "saving",
  Saved = "Saved",
}

// interface AutosaveStateChangeEventBase {
// }

export interface AutosaverStateChangeEvent {
  state: AutosaverState;
}

export interface AutosaverParams {
  debounceMs?: number;
  onStateChange?: (e: AutosaverStateChangeEvent) => unknown;
}

type SaveFunction = () => PromiseLike<unknown>;

export class Autosaver {
  private _state: AutosaverState = AutosaverState.Initial;
  private _saveFunction: SaveFunction;
  private _debouncedSave: SaveFunction & _.Cancelable;
  //private _debounceMs: number;
  private _onStateChange?: (e: AutosaverStateChangeEvent) => unknown;

  private _activeSaves: PromiseLike<unknown>[] = [];
  //private _debouncedSave:

  constructor(saveFunction: SaveFunction, params?: AutosaverParams) {
    this._saveFunction = () => {
      this._setState(AutosaverState.Saving);
      const savePromise = saveFunction();
      this._activeSaves.push(savePromise);
      savePromise.then(() => {
        this._activeSaves = this._activeSaves.filter((p) => p !== savePromise);
        if (
          this._activeSaves.length === 0 &&
          this._state === AutosaverState.Saving
        ) {
          this._setState(AutosaverState.Saved);
        }
      });

      return savePromise;
    };

    this._debouncedSave = _.debounce(
      this._saveFunction,
      params?.debounceMs ?? 0
    );
    this._onStateChange = params?.onStateChange;
  }

  saveIsPending() {
    return (
      this._state == AutosaverState.Modified ||
      this._state == AutosaverState.Saving
    );
  }

  /*
   * Call when something was modified to trigger the debounced save function.
   */
  modified() {
    this._debouncedSave();
    this._setState(AutosaverState.Modified);
  }

  /*
   * Starts a save immediately, without waiting for the debounced function
   */
  immediate() {
    this._debouncedSave.cancel();
    this._saveFunction();
  }

  //private _postSave(): Promise<void> {}
  private _setState(state: AutosaverState): void {
    if (this._state === state) {
      return;
    }

    this._state = state;
    this._onStateChange?.({ state: this._state });
  }
}
