import { BaseType, Selection } from "d3-selection";
import {
  EntryDatum,
  EntryMutators,
  EventDatum,
  EventMutators,
  Mutators,
  Week,
} from "./types";
import { DateRange } from "../common/types";

const d37 = d3; // limit warnings on refernce ot UMD global

export function showSearch(
  term: string,
  entries: EntryDatum[],
  events: EventDatum[],
  mutators: Mutators
) {
  show(`Search for '${term}'`, entries, events, mutators);
}

export function showOpen(
  entries: EntryDatum[],
  events: EventDatum[],
  mutators: Mutators
) {
  show("In Progress", entries, events, mutators);
}

export function showWeek(
  week: Week,
  entries: EntryDatum[],
  events: EventDatum[],
  mutators: Mutators
) {
  show(
    week.start.toLocaleDateString() +
      " - " +
      (week.end?.toLocaleDateString() ?? "?"),
    entries,
    events,
    mutators
  );
}

export function show(
  header: string,
  entries: EntryDatum[],
  events: EventDatum[],
  mutators: Mutators
) {
  d37.select("#details-header").text(header);

  const sortedEntries = entries.sort(
    (a, b) =>
      d37.descending(a.shown, b.shown) ||
      d37.descending(a.entry.ranges.length > 0, b.entry.ranges.length > 0) || // with any range comes first
      d37.ascending(a.entry.ranges[0]?.from, b.entry.ranges[0]?.from)
  );

  d37
    .select("div#details-content-entries")
    .selectAll("div.card")
    .data(sortedEntries, (d) => (d as EntryDatum).entry.title)
    .call(entryCards(mutators.entry));

  d37
    .select("div#details-content-events")
    .selectAll("div.card")
    .data(events, (d) => (d as EventDatum).event.title)
    .call(eventCards(mutators.event));
}

function entryCards(
  mutators: EntryMutators
): (
  detailsContent: Selection<BaseType, EntryDatum, BaseType, unknown>
) => void {
  return (detailsContent: Selection<BaseType, EntryDatum, BaseType, unknown>) =>
    detailsContent
      .join(
        (enter) =>
          enter
            .append("div")
            .classed("card", true)
            .on("mouseenter", function (e: MouseEvent, d: EntryDatum) {
              // console.log("entry enter");
              d.hiliteFromDetails = true;
              mutators.refresh();
            })
            .on("mouseleave", function (e: MouseEvent, d: EntryDatum) {
              d.hiliteFromDetails = false;
              // console.log("entry leave");
              mutators.refresh();
            })
            .call((card) =>
              card
                .append("i")
                .style("float", "right")
                .style("cursor", "pointer")
                .classed("bi", true)
                .classed("bi-trash-fill", true)
                .on("click", (e: MouseEvent, d: EntryDatum) => {
                  mutators.removeEntry(d.entry);
                })
            )
            .call((card) => card.append("h3").attr("id", "entry-title-header"))

            // editable title
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Title ").style("flex", "50px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "entry-title")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                    .on("change", (e: Event, d: EntryDatum) => {
                      const newTitle = (e.target as HTMLInputElement).value;
                      mutators.setTitle(d.entry, newTitle);
                      card.select("#entry-title-header").text(newTitle);
                    })
                )
            )

            // tags
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Tags ").style("flex", "50px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "entry-tags")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                )
                .on("change", (e: Event, d: EntryDatum) => {
                  const raw: string = (e.target as HTMLInputElement).value;
                  const parsedTags: string[] = raw
                    .split(",")
                    .map((t) => t.trim())
                    .filter((t) => t !== "");
                  const asString = parsedTags.join(", ");
                  (e.target as HTMLInputElement).value = asString; // onChange not called when set programatically
                  mutators.setTags(d.entry, parsedTags);
                })
            )

            // color
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Color ").style("flex", "50px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "entry-color")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                    .on("change", (e: Event, d) => {
                      mutators.setColor(
                        d.entry,
                        (e.target as HTMLInputElement).value
                      );
                    })
                )
            )

            // ranges
            .call((card) =>
              card
                .append("div")
                .call((block) =>
                  block
                    .append("div")
                    .attr("id", "entry-ranges-title")
                    .text("Ranges")
                )
                .call((block) =>
                  block
                    .append("textarea")
                    .attr("id", "entry-ranges")
                    .style("width", "100%")
                    .on("change", (e: Event, d: EntryDatum) => {
                      const target = e.target as HTMLTextAreaElement;
                      const raw: string = target.value;
                      const parsedRanges: DateRange[] | undefined =
                        parseRanges(raw);

                      if (parsedRanges === undefined) {
                        // not valid ranges text
                        d37
                          .select(target.parentNode as HTMLElement)
                          .select("#entry-ranges-title")
                          .style("color", "crimson");

                        return;
                      }

                      d37
                        .select(target.parentNode as HTMLElement)
                        .select("#entry-ranges-title")
                        .style("color", "");

                      target.value = rangesToString(parsedRanges); // onChange not called when set programatically
                      mutators.setRanges(d.entry, parsedRanges);
                    })
                )
            )

            // notes
            .call((card) =>
              card
                .append("div")
                .call((block) => block.append("div").text("Notes"))
                .call((block) =>
                  block
                    .append("textarea")
                    .attr("id", "entry-notes")
                    .style("width", "100%")
                    .on("change", (e: Event, d: EntryDatum) => {
                      mutators.setNotes(
                        d.entry,
                        (e.target as HTMLTextAreaElement).value
                      );
                    })
                )
            ),
        (update) => update,
        (exit) => exit.remove()
      )
      .classed("card-muted", (d) => !d.shown)
      .classed(
        "card-highlighted",
        (d) => d.hilite || d.hiliteFromTag || d.hiliteFromDetails
      )

      .call((card) =>
        card.select("#entry-title-header").text((d) => d.entry.title)
      )
      .call((card) =>
        card.select("#entry-title").attr("value", (d) => d.entry.title)
      )
      .call((card) =>
        card.select("#entry-tags").attr("value", (d) => d.entry.tags.join(", "))
      )
      .call((card) =>
        card.select("#entry-color").attr("value", (d) => d.entry.color ?? "")
      )
      .call((card) =>
        card
          .select("#entry-ranges")
          .text((d) => rangesToString(d.entry.ranges))
          .attr("rows", (d) => d.entry.ranges.length + 1)
      )
      .call((card) =>
        card
          .select("#entry-notes")
          .text((d) => d.entry.notes)
          .attr("rows", (d) => d.entry.notes.split("\n").length)
      );
}

function rangesToString(ranges: DateRange[]): string {
  return (
    ranges
      .map(
        (range) =>
          `${range.from.toLocaleDateString()} - ${
            range.to?.toLocaleDateString() ?? "?"
          }`
      )
      .join("\n") + "\n"
  );
}

function parseRanges(str: string): DateRange[] | undefined {
  const parsed: (DateRange | undefined)[] = str
    .split("\n")
    .filter((l) => l.trim() !== "")
    .map(parseRange);

  if (parsed.some((p) => p === undefined)) {
    return undefined;
  }

  return parsed as DateRange[];
}

function parseRange(str: string): DateRange | undefined {
  const pieces: string[] = str.split("-");
  if (pieces.length > 2) {
    return undefined;
  }

  const fromDate = parseDate(pieces[0]);
  if (fromDate === undefined) {
    return undefined;
  }
  // const from: number = Date.parse(pieces[0].trim());
  // if (isNaN(from)) {
  //   return undefined;
  // }

  // const fromDate = new Date(from);

  let to: number | undefined;
  if (pieces.length === 2) {
    if (pieces[1].trim() === "?") {
      to = undefined;
    } else {
      to = Date.parse(pieces[1].trim());
      if (isNaN(to)) {
        return undefined;
      }
    }
  }
  const toDate: Date | undefined = to === undefined ? undefined : new Date(to);

  return {
    from: fromDate,
    to: toDate,
  };
}

function parseDate(str: string): Date | undefined {
  const num: number = Date.parse(str.trim());
  return isNaN(num) ? undefined : new Date(num);
}

function eventCards(
  mutators: EventMutators
): (
  detailsContents: Selection<BaseType, EventDatum, BaseType, unknown>
) => void {
  return (detailsContent: Selection<BaseType, EventDatum, BaseType, unknown>) =>
    detailsContent
      .join(
        (enter) =>
          enter
            .append("div")
            .classed("card", true)
            .on("mouseenter", function (e: MouseEvent, d: EventDatum) {
              d.hiliteFromDetails = true;
              mutators.refresh();
            })
            .on("mouseleave", function (e: MouseEvent, d: EventDatum) {
              d.hiliteFromDetails = false;
              mutators.refresh();
            })
            .call((card) =>
              card
                .append("i")
                .style("float", "right")
                .style("cursor", "pointer")
                .classed("bi", true)
                .classed("bi-trash-fill", true)
                .on("click", (e: MouseEvent, d: EventDatum) => {
                  mutators.removeEvent(d.event);
                })
            )
            .call((card) => card.append("h3").attr("id", "event-title-header"))
            // editable title
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Title ").style("flex", "60px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "event-title")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                    .on("change", (e: Event, d: EventDatum) => {
                      const newTitle = (e.target as HTMLInputElement).value;
                      mutators.setTitle(d.event, newTitle);
                      card.select("#event-title-header").text(newTitle);
                    })
                )
            )

            // tags
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Tags ").style("flex", "60px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "event-tags")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                )
                .on("change", (e: Event, d: EventDatum) => {
                  const raw: string = (e.target as HTMLInputElement).value;
                  const parsedTags: string[] = raw
                    .split(",")
                    .map((t) => t.trim())
                    .filter((t) => t !== "");
                  const asString = parsedTags.join(", ");
                  (e.target as HTMLInputElement).value = asString; // onChange not called when set programatically
                  mutators.setTags(d.event, parsedTags);
                })
            )

            // bootstrap icon
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block
                    .append("span")
                    .text("BS Icon ")
                    .style("flex", "60px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "event-bootstrap-icon")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                    .on("change", (e: Event, d) => {
                      mutators.setBootstrapIcon(
                        d.event,
                        (e.target as HTMLInputElement).value
                      );
                    })
                )
            )

            // color
            .call((card) =>
              card
                .append("div")
                .style("display", "flex")
                .style("flex-direction", "row")

                .call((block) =>
                  block.append("span").text("Color ").style("flex", "60px 0 0")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "event-color")
                    .attr("type", "text")
                    .style("flex", 1)
                    .style("margin-left", "5px")
                    .on("change", (e: Event, d) => {
                      mutators.setColor(
                        d.event,
                        (e.target as HTMLInputElement).value
                      );
                    })
                )
            )

            // Date
            .call((card) =>
              card
                .append("div")
                .call((block) =>
                  block
                    .append("div")
                    .attr("id", "event-ranges-title")
                    .text("Date")
                )
                .call((block) =>
                  block
                    .append("input")
                    .attr("id", "event-date")
                    .style("width", "100%")
                    .on("change", (e: Event, d: EventDatum) => {
                      const target = e.target as HTMLInputElement;
                      const raw: string = target.value;
                      const parsedDate: Date | undefined = parseDate(raw);

                      if (parsedDate === undefined) {
                        // not valid date text
                        d37
                          .select(target.parentNode as HTMLElement)
                          .select("#event-date-title")
                          .style("color", "crimson");

                        return;
                      }

                      d37
                        .select(target.parentNode as HTMLElement)
                        .select("#event-date-title")
                        .style("color", "");

                      target.value = parsedDate.toLocaleDateString(); // onChange not called when set programatically
                      mutators.setDate(d.event, parsedDate);
                    })
                )
            )

            // notes
            .call((card) =>
              card
                .append("div")
                .call((block) => block.append("div").text("Notes"))
                .call((block) =>
                  block
                    .append("textarea")
                    .attr("id", "event-notes")
                    .style("width", "100%")
                    .on("change", (e: Event, d: EventDatum) => {
                      mutators.setNotes(
                        d.event,
                        (e.target as HTMLTextAreaElement).value
                      );
                    })
                )
            ),
        (update) => update,
        (exit) => exit.remove()
      )
      .classed("card-muted", (d) => !d.shown)
      .call((card) =>
        card.select("#event-title-header").text((d) => d.event.title)
      )
      .call((card) =>
        card.select("#event-title").attr("value", (d) => d.event.title)
      )
      .call((card) =>
        card.select("#event-tags").attr("value", (d) => d.event.tags.join(", "))
      )
      .call((card) =>
        card
          .select("#event-bootstrap-icon")
          .attr("value", (d) => d.event.bootstrapIcon ?? "")
      )
      .call((card) =>
        card.select("#event-color").attr("value", (d) => d.event.color ?? "")
      )
      .call((card) =>
        card
          .select("#event-date")
          .attr("value", (d) => d.event.date.toLocaleDateString())
      )
      .call((card) =>
        card
          .select("#event-notes")
          .text((d) => d.event.notes)
          .attr("rows", (d) => d.event.notes.split("\n").length)
      );
}
