// data transformations
import { buildArrangement, forEachWeekIndex, midCoords } from "./arrangement";
import { makeGrid, traceIslands } from "./grids";
import { Grid } from "./grids-types";
import {
  EntryDatum,
  TagMapEntry,
  CellDatum,
  AppSettings,
  AppState,
  AppCalculatedData,
  AppCalculatedArrangement,
  CellGrid,
  WeekIndex,
  TagMap,
  EventDatum,
} from "./types";
import { RawData, LIWEntry, LIWEvent } from "../common/types";

// deps...
// entryGrid <- gridWidth, gridHeight, col, row, weeks
// entryData <- gridWidth, gridHeight, midCoords, weekIndexer, todayWeekIndexer

export function buildAppState(
  rawData: RawData,
  settings: AppSettings
): AppState {
  const arrangement = buildArrangement(
    rawData.dateOfBirth,
    settings.weekArrangement
  );

  const calculated: AppCalculatedData = buildAppCalculated(
    rawData,
    arrangement
  );
  applyShownTags(settings, calculated);

  return {
    rawData,
    settings,
    arrangement,
    calculated,
  };
}

function buildAppCalculated(
  rawData: RawData,
  arr: AppCalculatedArrangement
): AppCalculatedData {
  const tagMap = buildTagMap(rawData);
  const [cellGrid, entryData, eventData] = buildCellGridAndData(
    rawData.entries,
    rawData.events,
    arr
  );

  return {
    cellGrid: cellGrid,
    entryData: entryData,
    eventData: eventData,
    tagMap: tagMap,
    tagEntries: buildTagMapEntries(tagMap),
  };
}

function buildCellGridAndData(
  entries: LIWEntry[],
  events: LIWEvent[],
  arr: AppCalculatedArrangement
): [CellGrid, EntryDatum[], EventDatum[]] {
  return rebuildCellGridAndData(entries, events, arr);
}

function rebuildCellGridAndData(
  entries: LIWEntry[],
  events: LIWEvent[],
  arr: AppCalculatedArrangement,
  previousEntryData?: EntryDatum[],
  previousEventData?: EventDatum[]
): [CellGrid, EntryDatum[], EventDatum[]] {
  const prevEntryMap: Map<string, EntryDatum> = new Map<string, EntryDatum>(
    previousEntryData?.map((e) => [e.entry.title, e] ?? [])
  );

  const prevEventMap: Map<string, EventDatum> = new Map<string, EventDatum>(
    previousEventData?.map((e) => [e.event.title, e] ?? [])
  );

  const cellGrid: CellGrid = makeGrid<CellDatum>(
    arr.gridWidth,
    arr.gridHeight,
    (_x, _y): CellDatum => ({
      entries: [],
      events: [],
    })
  );

  for (let i: number = 0; i < arr.weeks.length; i++) {
    const wi: WeekIndex = i as WeekIndex;

    cellGrid[arr.col(wi)][arr.row(wi)].weekIndex = wi;
    cellGrid[arr.col(wi)][arr.row(wi)].week = arr.weeks[i];
  }

  const entryData: EntryDatum[] = entries.map((entry) => {
    const grid: Grid<boolean> = makeGrid<boolean>(
      arr.gridWidth,
      arr.gridHeight,
      false
    );

    const prevEntry: EntryDatum | undefined = prevEntryMap.get(entry.title);

    const datum: EntryDatum = {
      entry: entry,
      path: "",
      shown: prevEntry?.shown ?? true,
      hilite: prevEntry?.hilite ?? false,
      hiliteFromTag: prevEntry?.hiliteFromTag ?? false,
      hiliteFromDetails: prevEntry?.hiliteFromDetails ?? false,
    };

    const todayWeekIndex = arr.weekIndexer(new Date());
    // fill grids: bool grid for marked squares, and entry grid with data
    forEachWeekIndex(
      entry.ranges,
      arr.weekIndexer,
      todayWeekIndex,
      (wi: WeekIndex) => {
        cellGrid[arr.col(wi)][arr.row(wi)].entries.push(datum);
        grid[arr.col(wi)][arr.row(wi)] = true;
      }
    );

    const [path, _debugGrid] = traceIslands(grid, midCoords);
    datum.path = path;
    return datum;
  });

  const eventData: EventDatum[] = events.map((event) => {
    const prevEvent: EventDatum | undefined = prevEventMap.get(event.title);

    const wi = arr.weekIndexer(event.date);

    const datum: EventDatum = {
      event: event,
      shown: prevEvent?.shown ?? true,
      weekIndex: wi,
      hilite: prevEvent?.hilite ?? false,
      hiliteFromTag: prevEvent?.hiliteFromTag ?? false,
      hiliteFromDetails: prevEvent?.hiliteFromDetails ?? false,
    };

    cellGrid[arr.col(wi)][arr.row(wi)].events.push(datum);
    return datum;
  });

  return [cellGrid, entryData, eventData];
}

export function allTags(rawData: RawData): string[] {
  return [
    ...new Set([
      ...rawData.entries.flatMap((e) => e.tags),
      ...rawData.events.flatMap((e) => e.tags),
    ]),
  ];
}

export function buildTagMap(rawData: RawData): TagMap {
  return rebuildTagMap(rawData);
}

function rebuildTagMap(rawData: RawData, tagMap?: TagMap) {
  return new Map<string, TagMapEntry>(
    allTags(rawData).map((tag) => [
      tag,
      {
        tag: tag,
        metadata: rawData.tags[tag],
        entries: rawData.entries.filter((e) => e.tags.includes(tag)),
        events: rawData.events.filter((e) => e.tags.includes(tag)),
        hilite: tagMap?.get(tag)?.hilite ?? false,
        hiliteFromCell: tagMap?.get(tag)?.hiliteFromCell ?? false,
        shown: tagMap?.get(tag)?.shown ?? true,
      },
    ])
  );
}

export function rebuildTags(
  rawData: RawData,
  data: AppCalculatedData
): AppCalculatedData {
  const tagMap = rebuildTagMap(rawData, data.tagMap);
  return {
    entryData: data.entryData,
    eventData: data.eventData,
    cellGrid: data.cellGrid,
    tagMap: tagMap,
    tagEntries: buildTagMapEntries(tagMap),
  };
}

export function rebuildEntriesAndEvents(
  entries: LIWEntry[],
  events: LIWEvent[],
  arr: AppCalculatedArrangement,
  prev: AppCalculatedData
): AppCalculatedData {
  const [cellGrid, entryData, eventData] = rebuildCellGridAndData(
    entries,
    events,
    arr,
    prev.entryData,
    prev.eventData
  );

  return {
    cellGrid: cellGrid,
    entryData: entryData,
    eventData: eventData,
    tagMap: prev.tagMap,
    tagEntries: prev.tagEntries,
  };
}

export function buildTagMapEntries(tagMap: TagMap): TagMapEntry[] {
  return [...tagMap.values()];
}

export function applyShownTags(settings: AppSettings, data: AppCalculatedData) {
  const shown: Set<string> = new Set(settings.shownTags);

  data.tagEntries.map((t) => (t.shown = shown.has(t.tag)));
  data.entryData.map(
    (e) =>
      (e.shown =
        e.entry.tags.length === 0 || e.entry.tags.some((t) => shown.has(t)))
  );
}
